.app{
    display: flex;
    flex-direction: column;
    background-color: var(--navy);
}
.section-title {
    display: flex;
    margin-bottom: 1em;
    font-family: LeagueSpartan;
    color: var(--lightest-slate);
    font-size: 2.875em;
}
.section-title::after {
    content: "";
    width: 7em;
    margin-top: 0.85em;
    height: 0.035em;
    margin-left: 0.45em;
    background-color: var(--lightest-navy);
}
.fade-in-section {
    opacity: 0;
    transform: translateY(10vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1s ease-out;
    will-change: opacity, visibility;
}
.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}
html {
    scroll-behavior: smooth;
}
:root {
    --dark-navy: #020c1b;
    --navy: #0a192f;
    --light-navy: #112240;
    --lightest-navy: #233554;
    --navy-shadow: rgba(2, 12, 27, 0.7);
    --slate: #8892b0;
    --light-slate: #a8b2d1;
    --lightest-slate: #ccd6f6;
    --white: #e6f1ff;
    --green-bright: #64ffda;
    --green-tint: rgba(100, 255, 218, 0.1);
}
@font-face {
    font-family: "NTR";
    font-weight: 200;
    font-display: fallback;
    src: url("./fonts/NTR-Regular.ttf")
    format("truetype");
}
@font-face {
    font-family: "LeagueSpartan";
    src: url("./fonts/LeagueSpartan.otf")
    format("opentype");
}